import { Fragment, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { func } from "prop-types";

import scrollIfNeeded from "../../utilities/scroll-if-needed";
import {
  updateShoppingSelections,
  setFilterBy,
  setSortBy,
} from "./ShoppingRedux";
import useFilteredAndSortedBenefits from "./use-filtered-and-sorted-benefits";
import useSelectedBenefits from "./use-selected-benefits";
import trackEvent from "../tracking/track-event";

// import ShoppingFeaturedBenefit from "./ShoppingFeaturedBenefit";
import ShoppingBenefitListItem from "./ShoppingBenefitListItem";
import ShoppingBenefitsUnhideButton from "./ShoppingBenefitsUnhideButton";
import ShoppingCart from "./ShoppingCart";
import ShoppingFilterAndSort from "./ShoppingFilterAndSort";
import ShoppingSelectAmount from "./ShoppingSelectAmount";
import ShoppingSelectQuantity from "./ShoppingSelectQuantity";
import ShoppingUsedAllPoints from "./ShoppingUsedAllPoints";

import useShoppingBenefitTypes from "./use-shopping-benefit-types";

import { Notification, Typography } from "@uhc-tempo/components";
import IndividualShoppingCallouts from "./IndividualShoppingCallouts";
const { H3, Paragraph } = Typography;

// function convertFeaturedBenefitsArray(array) {
//   const featuredCallouts = {
//     "custom-team-event": "An all-agency or team personalized event.",
//     "talent-sourcing": "Agency-tailored help in filling key positions.",
//     "uhc-innovation-experience":
//       "An all-expense paid trip for 3 to The Circle.",
//   };

//   const filteredItems = array.filter((item) =>
//     Object.keys(featuredCallouts).includes(item.slug)
//   );

//   const modifiedItems = filteredItems.map((item) => ({
//     ...item,
//     featuredCallout: featuredCallouts[item.slug],
//   }));

//   return modifiedItems;
// }

export default function ShoppingSelection({ handleSubmit }) {
  const [error, setError] = useState(null);
  const [selectionInProgress, setSelectionInProgress] = useState(0);
  const [viewSelectAmount, setViewSelectAmount] = useState(null);
  const [viewSelectQuantity, setViewSelectQuantity] = useState(null);
  const filterBy = useSelector((state) => state.shoppingFilterSort?.filterBy);
  const sortBy = useSelector((state) => state.shoppingFilterSort?.sortBy);

  // TO-DO: integrate with useModalScrollbarOffset hook
  const [showUsedAllPoints, setShowUsedAllPoints] = useState(false);

  const dispatch = useDispatch();
  const hasUserInteraction = useRef(false);
  const errorAlert = useRef();

  const dispatchFilterBy = (val) => dispatch(setFilterBy(val));
  const dispatchSortBy = (val) => dispatch(setSortBy(val));

  let { selections, pointsRemaining } = useSelectedBenefits();
  let sortedAndFilteredBenefits = useFilteredAndSortedBenefits({
    filterBy,
    sortBy,
  });

  // storing featured benefits in a ref so they won't change with filters
  // const featuredBenefits = useRef(
  //   convertFeaturedBenefitsArray(sortedAndFilteredBenefits)
  // );

  const shoppingBenefitTypes = useShoppingBenefitTypes();

  function displayError(errorMessage) {
    setError(errorMessage);
    setSelectionInProgress(0);

    setTimeout(() => {
      scrollIfNeeded(errorAlert.current);
    }, 50);
  }

  function handleRemove(id, name) {
    setError(null);
    setSelectionInProgress(id);

    const update = selections.filter((selection) => selection.id !== id);

    trackSelections("benefit_remove", id, name);
    updateSelections(update);
  }

  function handleSelect(id, name) {
    setError(null);
    setSelectionInProgress(id);

    const update = [...selections, { id, qty: 1 }];

    trackSelections("benefit_selection", id, name);
    updateSelections(update);
  }

  function handleViewDetail(benefit) {
    if (selectionInProgress) {
      return;
    }

    trackEvent({
      action: "shopping_benefit_learn_more",
      relatedId: benefit.id,
      params: {
        benefit_id: benefit.id,
        benefit_name: benefit.name,
        triggered_from: "index",
      },
    });
  }

  function handleSelectMultiple(id, name, qty) {
    setError(null);
    setSelectionInProgress(id);

    let action;
    let update = selections.filter((selection) => selection.id !== id);

    if (!!qty) {
      action = "benefit_selection";
      update.push({ id, qty });
    } else {
      action = "benefit_remove";
    }

    trackSelections(action, id, name);
    updateSelections(update);
  }

  function handleViewSelectAmount(benefit) {
    if (selectionInProgress) {
      return;
    }

    trackEvent({
      action: "shopping_benefit_select_amount",
      relatedId: benefit.id,
      params: {
        benefit_id: benefit.id,
        benefit_name: benefit.name,
        triggered_from: "index",
      },
    });

    const selected = selections.find(
      (selection) => selection.id === benefit.id
    );
    // sometimes qty means amount :shrug:
    const qty = !!selected && !!selected.qty ? selected.qty : 0;

    setViewSelectAmount({ ...benefit, qty });
  }

  function handleViewSelectQuantity(benefit) {
    if (selectionInProgress) {
      return;
    }

    trackEvent({
      action: "shopping_benefit_select_quantity",
      relatedId: benefit.id,
      params: {
        benefit_id: benefit.id,
        benefit_name: benefit.name,
        triggered_from: "index",
      },
    });

    const selected = selections.find(
      (selection) => selection.id === benefit.id
    );
    const qty = !!selected && !!selected.qty ? selected.qty : 0;

    setViewSelectQuantity({ ...benefit, qty });
  }

  function isInProgress(id) {
    return id === selectionInProgress;
  }

  function isSelected(id) {
    return selections.some((selection) => selection.id === id);
  }

  function trackSelections(action, id, name) {
    trackEvent({
      action,
      relatedId: id,
      params: {
        selections,
        benefit_id: id,
        benefit_name: name,
        triggered_from: "index",
      },
    });
  }

  function onRemove(benefit) {
    if (!benefit) {
      return;
    }

    if (benefit.allowMultiple === "quantity") {
      handleViewSelectQuantity(benefit);
    } else if (benefit.allowMultiple === "amount") {
      handleViewSelectAmount(benefit);
    } else {
      handleRemove(benefit.id, benefit.name);
    }
  }

  function onSelect(benefit) {
    if (!benefit) {
      return;
    }

    if (benefit.allowMultiple === "quantity") {
      handleViewSelectQuantity(benefit);
    } else if (benefit.allowMultiple === "amount") {
      handleViewSelectAmount(benefit);
    } else {
      handleSelect(benefit.id, benefit.name);
    }
  }

  function updateSelections(update) {
    hasUserInteraction.current = true;

    dispatch(updateShoppingSelections(update))
      .unwrap()
      .then((response) => {
        setSelectionInProgress(0);
      })
      .catch((err) => {
        displayError(err.message || "Error");
      });
  }

  useEffect(() => {
    setShowUsedAllPoints(hasUserInteraction.current && pointsRemaining <= 0);
  }, [pointsRemaining]);

  return (
    <>
      {!!error && (
        <div ref={errorAlert}>
          <Notification
            className="tds-margin-xxlg-bottom"
            dismissClickHandler={() => setError(null)}
            isDismissed={!error}
            notificationType="error"
          >
            <Paragraph className="tds-margin-none">{error}</Paragraph>
          </Notification>
        </div>
      )}
      <div className="shopping-selection">
        <div className="shopping-featured-benefit ua-display-flex ua-grid-gap-2-md ua-grid-gap-3-lg">
          {/* {!!featuredBenefits.current &&
            featuredBenefits.current.map((featuredBenefit, index) => (
              <div
                className={`ua-flex-col-${featuredBenefits.length}-up-md`}
                key={index}
              >
                <ShoppingFeaturedBenefit
                  benefit={featuredBenefit}
                  hasMultipleAmounts={!!featuredBenefit.multipleAmounts}
                />
              </div>
            ))} */}
          <IndividualShoppingCallouts />
        </div>
        <div className="shopping-selection__meta">
          <div
            className={`ua-display-flex ua-align-items-flex-end ua-justify-content-end ua-grid-gap-3-md ${
              !!sortBy && !filterBy ? "ua-padding-xxxxlg-bottom" : ""
            }`}
          >
            <ShoppingFilterAndSort
              currentFilterBy={filterBy}
              currentSortBy={sortBy}
              onFilter={dispatchFilterBy}
              onSort={dispatchSortBy}
            />
            <ShoppingBenefitsUnhideButton onError={displayError} />
            <ShoppingCart
              handleSubmit={handleSubmit}
              quantity={selections.length}
            />
          </div>
        </div>
        {!sortBy || (!!sortBy && !!filterBy) ? (
          <div className={`shopping-selection--list`}>
            {shoppingBenefitTypes.map((option) => (
              <Fragment key={option.id}>
                {(!filterBy || filterBy === option.id) && (
                  <H3 className="shopping-selection__section-heading ua-margin-sm-bottom ua-text-sentence-case">
                    {option.name.toLowerCase()}
                  </H3>
                )}
                {sortedAndFilteredBenefits.map((benefit) => (
                  <Fragment key={`benefit-${benefit.id}`}>
                    {option.id === benefit.benefit_type_id && (
                      <ShoppingBenefitListItem
                        {...benefit}
                        key={`benefit-${benefit.id}`}
                        disabled={!!selectionInProgress}
                        inProgress={isInProgress(benefit.id)}
                        isSelected={isSelected(benefit.id)}
                        onError={displayError}
                        onRemove={() => onRemove(benefit)}
                        onSelect={() => onSelect(benefit)}
                        onViewDetail={() => handleViewDetail(benefit)}
                        unavailable={pointsRemaining < benefit.points}
                        slug={benefit.slug}
                      />
                    )}
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </div>
        ) : (
          <>
            {sortedAndFilteredBenefits.map((benefit) => (
              <ShoppingBenefitListItem
                {...benefit}
                key={`benefit-${benefit.id}`}
                disabled={!!selectionInProgress}
                inProgress={isInProgress(benefit.id)}
                isSelected={isSelected(benefit.id)}
                onError={displayError}
                onRemove={() => onRemove(benefit)}
                onSelect={() => onSelect(benefit)}
                onViewDetail={() => handleViewDetail(benefit)}
                unavailable={pointsRemaining < benefit.points}
                slug={benefit.slug}
              />
            ))}
          </>
        )}
        <ShoppingSelectAmount
          benefit={viewSelectAmount}
          disabled={!!selectionInProgress}
          handleSelect={handleSelectMultiple}
          inProgress={!!viewSelectAmount && isInProgress(viewSelectAmount.id)}
          pointsRemaining={pointsRemaining}
          setViewSelectAmount={setViewSelectAmount}
        />
        <ShoppingSelectQuantity
          benefit={viewSelectQuantity}
          disabled={!!selectionInProgress}
          handleSelect={handleSelectMultiple}
          inProgress={
            !!viewSelectQuantity && isInProgress(viewSelectQuantity.id)
          }
          pointsRemaining={pointsRemaining}
          setViewSelectQuantity={setViewSelectQuantity}
        />
        <ShoppingUsedAllPoints
          open={showUsedAllPoints}
          handleClose={() => setShowUsedAllPoints(false)}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
}

ShoppingSelection.propTypes = {
  handleSubmit: func.isRequired,
};
