import { SideNavJump, Typography } from "@uhc-tempo/components";
import { useLocation } from "react-router-dom";

import useMetaTag from "../../utilities/use-meta-tag";
import ResourceContinuingEducation from "../../features/training-resources/ResourceContinuingEducation";
import ResourceLibraryData from "../../data/training-resources/training-resources.json";
import ResourceLibrary from "../../features/training-resources/ResourceLibrary";

const { H1, Paragraph } = Typography;

const ceCoursesSlug = "ce-courses";
const linkTitles = [
  "CE courses",
  ...ResourceLibraryData.map((item, i) => item.navTitle),
];
const linkAnchors = [
  `#${ceCoursesSlug}`,
  ...ResourceLibraryData.map((item, i) => `#${item.slug}`),
];

export default function TrainingResources() {
  useMetaTag({ name: "robots", content: "noindex, nofollow" });
  const location = useLocation();
  const hash = location.hash || `#${ceCoursesSlug}`;

  return (
    <section className="fluid-container ua-padding-xxxxlg-vertical">
      <div className="ua-grid ua-grid-gap-2-sm ua-grid-gap-3-lg ua-grid-cols-12 ua-padding-jumbo-bottom">
        <div className="ua-col-span-1-3-md ua-col-span-1-12-sm ua-display-none ua-display-block-md">
          <SideNavJump
            className="side-nav-jump-layout__side-nav ua-side-nav--align-heading"
            currentAnchor={hash}
            header="Training categories"
            linkTitles={linkTitles}
            linkAnchors={linkAnchors}
          />
        </div>
        <div className="ua-col-span-4-9-md ua-col-span-1-12-sm">
          <H1>Training Resources</H1>
          <Paragraph className="ua-font-size-h5 ua-font-weight-bold ua-line-height-1_4 ua-margin-none">
            Designed for United Advantage Platinum and Diamond agencies.
          </Paragraph>
          <Paragraph className="ua-font-size-h5 ua-line-height-1_4 ua-margin-xxxxlg-bottom">
            At UnitedHealthcare, we’re dedicated to helping you gain a deeper
            understanding of key topics essential to your team. Explore
            frequently requested areas, including industry fundamentals,
            underwriting, and&nbsp;more.
          </Paragraph>
          <ResourceContinuingEducation ceCoursesSlug={ceCoursesSlug} />
          <ResourceLibrary library={ResourceLibraryData} />
        </div>
      </div>
    </section>
  );
}
