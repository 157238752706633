import { useSelector } from "react-redux";

import useMetaTag from "../../utilities/use-meta-tag";
import ROUTES from "../../data/constants/routes";

import Embed from "../../components/embed/embed";
import MastheadAlt from "../../components/masthead-alt/MastheadAlt";
import VimeoPlayer from "../../components/vimeo-player/VimeoPlayer";

export default function PerkHighlights() {
  useMetaTag({ name: "robots", content: "noindex, nofollow" });

  const videoUrl = useSelector(({ settings }) => settings?.welcome_video) || "";
  const isVimeo = !!videoUrl && videoUrl.includes("vimeo");

  return (
    <div className="ua-recap ua-bg-primary-one ua-full-height">
      <header className="ua-recap--header">
        <MastheadAlt className="ua-recap--header--masthead" />
      </header>
      {videoUrl && (
        <div className="ua-recap--content sixteen-by-nine">
          {isVimeo ? (
            <VimeoPlayer vimeoUrl={videoUrl} />
          ) : (
            <Embed title={ROUTES.perkHighlights.title} url={videoUrl} />
          )}
        </div>
      )}
    </div>
  );
}
