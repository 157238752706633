import { Button, Typography } from "@uhc-tempo/components";
const { H6 } = Typography;

function FeaturedBenefit({ image, headline, body, link, linkText }) {
  return (
    <div className="shopping-featured-benefit__card ua-display-flex ua-flex-column ua-bg-primary-three ua-border-radius-md">
      {image ? (
        <div
          className="shopping-featured-benefit__card__image ua-border-radius-top-md"
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
      ) : null}
      <div className="shopping-featured-benefit__card__tag ua-bg-primary-two ua-text-primary-three ua-font-sans ua-width-fit-content">
        {headline}
      </div>
      <div className="shopping-featured-benefit__card__content ua-padding-lg-horizontal ua-padding-xxlg-bottom ua-flex-grow ua-display-flex ua-flex-column">
        <div className="shopping-featured-benefit__card__label ua-text-accent-one ua-font-sans">
          <span>
            Included{" "}
            <em style={{ fontWeight: "normal" }}>(no points required)</em>
          </span>
        </div>
        <H6 className="ua-line-clamp-sm-2 ua-flex-grow">{body}</H6>
        <Button
          buttonType={`secondary-one`}
          className="ua-text-nowrap ua-width-fit-content"
          onClick={() => {
            window.open(link, "_blank");
          }}
        >
          {linkText}
        </Button>
      </div>
    </div>
  );
}

export default function IndividualShoppingCallouts() {
  const featuredBenefits = [
    {
      headline: "CE COURSES",
      body: "Stay up-to-date with 25+ complimentary courses.",
      link: "https://www.netstudy.com/uhc/insurance/courses/catalog",
      linkText: "View options",
      image: "/images/ce-courses.png",
    },
    {
      headline: "ON-DEMAND TRAINING",
      body: "24/7 access to relevant industry training.",
      link: "https://unitedadvantage.com/training-resources",
      linkText: "See library",
      image: "/images/on-demand.png",
    },
    {
      headline: "COMPANIES LIKE ME",
      body: "Benchmark tool for dental and medical products.",
      link: "https://www.companieslikeme.com/",
      linkText: "Run a report now",
      image: "/images/companies-like-me.png",
    },
  ];
  return (
    <>
      {featuredBenefits.map((benefit, i) => (
        <div key={i} className={`ua-flex-col-3-up-md`}>
          <FeaturedBenefit {...benefit} />
        </div>
      ))}
    </>
  );
}
