import trackLinkClick from "../tracking/track-link-click";

import ResourceLink from "./ResourceLink";

import { Typography } from "@uhc-tempo/components";
const { H2, Link, Paragraph } = Typography;

export default function ResourceContinuingEducation({ ceCoursesSlug }) {
  return (
    <div
      id={ceCoursesSlug}
      className="ua-border-radius-md ua-bg-supporting-one-opacity-ten ua-padding-xxxxlg"
    >
      <H2 headingStyle={3} className="ua-line-height-1">
        <span className="ua-display-block ua-font-sans ua-font-size-paragraph-default ua-font-weight-bold ua-padding-top-lg ua-text-supporting-three">
          ON DEMAND
        </span>
        Continuing education courses
      </H2>
      <Paragraph bodyStyle={1} className="ua-padding-xxjumbo-right-lg">
        Your agency has access to{" "}
        <strong className="ua-font-weight-bold">
          25 complimentary continuing education (CE) courses
        </strong>{" "}
        through NetStudy. Choose <span className="ua-text-underline">any</span>{" "}
        approved course, regardless of credit hours, to help maintain employee
        licensure.{" "}
        <strong className="ua-font-weight-bold">
          Unused&nbsp;courses will expire January&nbsp;31, 2026.
        </strong>
      </Paragraph>
      <Paragraph className="ua-font-weight-bold ua-text-primary-one">
        How to Register:
      </Paragraph>
      <ol className="tds-text__paragraph ua-margin-md-left ua-padding-md-left">
        <li className="ua-margin-sm-bottom">
          Access the course portal via the United Advantage “Go to the{" "}
          <strong className="ua-font-weight-bold">CE Course Portal</strong>”
          button below.
        </li>
        <li>Register and check your remaining credits.</li>
      </ol>
      <Paragraph className="ua-padding-xsm-top" useItalic={true}>
        You need your{" "}
        <strong className="ua-font-weight-bold">
          agency reward account number
        </strong>{" "}
        to register. Don’t have it? Email{" "}
        <Link
          className="ua-font-weight-normal"
          href="mailto:unitedadvantage@uhc.com?subject=UA%20Agency%20RA%23%20Request"
          onClick={() =>
            trackLinkClick({
              event: "email_support",
              label: "Email",
              link: "mailto:unitedadvantage@uhc.com",
              triggered_from: "training-resources",
            })
          }
          rel="noopener noreferrer"
          target="_blank"
        >
          unitedadvantage@uhc.com
        </Link>{" "}
        to request it.
      </Paragraph>
      <Paragraph
        bodyStyle={3}
        className="ua-line-height-1_4 ua-margin-xxxlg-bottom"
        useItalic={true}
      >
        NetStudy offers web-based courses for Continuing Education (CE credits),
        listed by state. Access courses via the blue “CE Course Portal” button
        below to register and check your agency’s remaining credits. Courses are
        non-transferable between agency offices or states. Your agency has
        access to 25 courses, available 24/7. UnitedHealthcare covers the cost
        of these courses; however, any State Department of Insurance fees are
        the broker’s responsibility.
      </Paragraph>
      <Paragraph className="ua-margin-none ua-text-center">
        <ResourceLink
          link="https://www.netstudy.com/uhc"
          linkText={
            <span>
              <span className="ua-font-weight-normal">Go to the</span> CE Course
              Portal
            </span>
          }
        />
      </Paragraph>
    </div>
  );
}
