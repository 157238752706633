import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import {
  arrayOf,
  bool,
  element,
  func,
  node,
  oneOfType,
  string,
} from "prop-types";

import ROUTES from "../data/constants/routes";
import setActiveTitle from "../utilities/set-active-title";
import trackPageView from "../features/tracking/track-page-view";
import useAuthStatus from "../features/auth/use-auth-status";
import { usePageLayoutContext } from "../features/page-layout/PageLayout";

export default function AllowedComponent({
  bgBleed = false,
  component: Component = null,
  dynamic = false,
  forceHideHeader = false,
  forceHideNav = false,
  path = ROUTES.default.path,
  restricted = true,
  restrictedToLevels,
  restrictedToRoles,
  reverseHeader = false,
  title,
  ...rest
}) {
  let [hasAuth] = useAuthStatus();
  const defaultToShoppingBonusIfEnabled = useRef(true);
  const location = useLocation();
  const adminTokenLoading = useSelector(
    ({ adminTokenLoading }) => adminTokenLoading
  );
  const agencyLevel = useSelector(
    ({ user }) => !!user?.agency && user.agency?.level
  );
  const bonusShoppingRequired = useSelector(
    (state) => state.bonusShoppingRequired
  );
  const showComingSoon = useSelector(({ user }) => user.showComingSoon);
  const shoppingRequired = useSelector(
    ({ shoppingRequired }) => shoppingRequired?.required
  );
  const userTokenLoading = useSelector(
    ({ userTokenLoading }) => userTokenLoading
  );
  const userRole = useSelector(({ user }) => user?.role);
  const individualShopping = useSelector(({ user }) => user?.shoppingEnabled);
  const {
    canInvite,
    setBgBleed,
    setForceHideHeader,
    setForceHideNav,
    setReverseHeader,
  } = usePageLayoutContext();

  // let defaultRedirect = hasAuth ? ROUTES.dashboard.path : ROUTES.login.path;
  let defaultRedirect = hasAuth ? ROUTES.dashboard.path : ROUTES.default.path;

  // const allowed = !restricted || (restricted && hasAuth);
  const allowed = useMemo(() => {
    if (!restricted) {
      return true;
    }

    if (path === ROUTES.invite.path && !canInvite) {
      return false;
    }

    if (restricted && hasAuth) {
      const allowedLevel = checkLevel(agencyLevel, restrictedToLevels);
      const allowedRole = checkRole(userRole, restrictedToRoles);
      if (path.includes("shopping") && individualShopping) {
        return allowedLevel;
      }
      return allowedLevel && allowedRole;
    }

    return false;
  }, [
    agencyLevel,
    canInvite,
    hasAuth,
    individualShopping,
    path,
    restricted,
    restrictedToLevels,
    restrictedToRoles,
    userRole,
  ]);

  function handlePageView(pageTitle) {
    const { pathname, search } = location;
    setActiveTitle(pageTitle);
    trackPageView({ pathname, search }, hasAuth);
  }

  // Set background bleed layout, if needed
  useEffect(() => {
    setBgBleed(bgBleed);
  }, [bgBleed, setBgBleed]);

  // Force hide header, if needed
  useEffect(() => {
    setForceHideHeader(forceHideHeader);
  }, [forceHideHeader, setForceHideHeader]);

  // Force hide nav, if needed
  useEffect(() => {
    setForceHideNav(forceHideNav);
  }, [forceHideNav, setForceHideNav]);

  // Use reverse, if needed
  useEffect(() => {
    setReverseHeader(reverseHeader);
  }, [reverseHeader, setReverseHeader]);

  if (adminTokenLoading || userTokenLoading) {
    return null;
  }

  // If requesting default page, check authentication
  // and redirect to the appropiate page
  if ([ROUTES.default.path, defaultRedirect].includes(path)) {
    // Redirect to bonus shopping page after initial login
    // if bonusShoppingRequired is true
    if (
      hasAuth &&
      defaultToShoppingBonusIfEnabled.current &&
      bonusShoppingRequired &&
      checkLevel(agencyLevel, ROUTES.shoppingBonusIntro.restrictedToLevels) &&
      checkRole(userRole, ROUTES.shoppingBonusIntro.restrictedToRoles)
    ) {
      defaultRedirect = ROUTES.shoppingBonusIntro.path;
    }

    // Redirect to shopping page if shoppingRequired is true
    else if (
      hasAuth &&
      shoppingRequired &&
      checkLevel(agencyLevel, ROUTES.shopping.restrictedToLevels) &&
      (checkRole(userRole, ROUTES.shopping.restrictedToRoles) ||
        individualShopping)
    ) {
      defaultRedirect = ROUTES.shopping.path;
    }

    // Else redirect to coming soon page if showComingSoon is true
    else if (hasAuth && showComingSoon) {
      defaultRedirect = ROUTES.comingSoon.path;
    }

    // Else redirect to scorecard if dashboard (aka home) is restricted
    else if (
      hasAuth &&
      !checkLevel(agencyLevel, ROUTES.dashboard.restrictedToLevels)
    ) {
      defaultRedirect = ROUTES.status.path;
    }
    // Redirect if needed
    if (path !== defaultRedirect) {
      return (
        <Navigate to={{ pathname: defaultRedirect, search: location.search }} />
      );
    }
  }

  // Redirect shopping pages to default if bonusShoppingRequired
  // and shoppingRequired is false
  if (
    path.includes(ROUTES.shopping.path) &&
    !bonusShoppingRequired &&
    !shoppingRequired
  ) {
    return (
      <Navigate to={{ pathname: defaultRedirect, search: location.search }} />
    );
  }

  // Redirect coming soon page to default if showComingSoon is false
  if (path === ROUTES.comingSoon.path && !showComingSoon) {
    return (
      <Navigate to={{ pathname: defaultRedirect, search: location.search }} />
    );
  }

  // Load the requested component if the user is allowed to view it
  if (allowed) {
    // Handle static page views
    if (!dynamic) {
      handlePageView(title);
    }

    if (path === ROUTES.shopping.path) {
      defaultToShoppingBonusIfEnabled.current = false;
    } else if (path === ROUTES.login.path) {
      defaultToShoppingBonusIfEnabled.current = true;
    }

    return <Component handlePageView={handlePageView} {...rest} />;
  }

  // Redirect to the default page if the user is authenticated,
  // but not allowed to view the requested route
  if (!allowed && hasAuth) {
    return <Navigate to={{ pathname: defaultRedirect }} />;
  }

  // Otherwise, redirect to the login page and
  // store the original request for later
  handlePageView(ROUTES.login.title);

  return (
    <Navigate
      replace
      state={{ from: prepareFromLocation(location) }}
      to={ROUTES.login.path}
    />
  );
}

AllowedComponent.propTypes = {
  bgBleed: oneOfType([bool, string]),
  component: oneOfType([element, func, node]),
  dynamic: bool,
  forceHideHeader: bool,
  forceHideNav: bool,
  restricted: bool,
  restrictedToLevels: arrayOf(string),
  restrictedToRoles: arrayOf(string),
  title: string,
};

export function checkLevel(level, allowedLevels) {
  return !allowedLevels || (!!level && allowedLevels.includes(level));
}

function checkRole(role, allowedRoles) {
  return !allowedRoles || (!!role && allowedRoles.includes(role));
}

function prepareFromLocation(location) {
  try {
    const fromLocation = { ...location };

    // Clean up admin token from URL
    if (!!fromLocation.search) {
      const urlParams = new URLSearchParams(fromLocation.search);

      urlParams.delete("token");
      urlParams.delete("admin");
      urlParams.delete("agency_id");
      urlParams.delete("user_id");

      fromLocation.search = urlParams.toString();
    }

    return fromLocation;
  } catch (err) {
    return { ...location };
  }
}
