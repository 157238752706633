import { memo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import ROUTES from "../data/constants/routes";

import useAuthToken from "../features/auth/use-auth-token";
import useLoadData from "./use-load-data";
import useWebVitals from "../features/tracking/use-web-vitals";

import AdminBar from "../features/admin/AdminBar";
import AllowedComponent from "./AllowedComponent";
import PageLayout from "../features/page-layout/PageLayout";
import ScrollRestoration from "./scroll-restoration";
import TrackPageScroll from "../features/tracking/track-page-scroll";

import Dashboard from "../pages/dashboard";
import Dcsm from "../pages/dcsm";
// import Coach from "../pages/coach";
import ComingSoon from "../pages/coming-soon";
import Expectations from "../pages/expectations";
import ForgotPassword from "../pages/password/forgot";
import Home from "../pages/home";
import Invite from "../pages/invite";
import Login from "../pages/login";
import NotFound from "../pages/not-found";
import PerkDetails from "../pages/benefits/PerkDetails";
import PerkHighlights from "../pages/perk-highlights";
import Privacy from "../pages/privacy";
import Profile from "../pages/profile";
import Recap from "../pages/recap";
import RequestAgencyId from "../pages/register/request-agency-id";
import Register from "../pages/register";
import RegisterUser from "../pages/register/register-user";
import ResetPassword from "../pages/password/reset";
import ShoppingBonusChoose from "../pages/shopping/bonus/choose";
import ShoppingBonusConfirmationLater from "../pages/shopping/bonus/confirmation-later";
import ShoppingBonusConfirmationNow from "../pages/shopping/bonus/confirmation-now";
import ShoppingBonusIntro from "../pages/shopping/bonus/intro";
import ShoppingBonusReviewNow from "../pages/shopping/bonus/review-now";
import Shopping from "../pages/shopping";
import ShoppingChoose from "../pages/shopping/choose";
import ShoppingConfirmation from "../pages/shopping/confirmation";
import ShoppingIntro from "../pages/shopping/intro";
import ShoppingReview from "../pages/shopping/review";
import Status from "../pages/status";
import TermsConditions from "../pages/terms-conditions";
import TermsUse from "../pages/terms-use";
import ThankYou from "../pages/thankyou";
import Training from "../pages/benefits/training";
import TrainingDetails from "../pages/benefits/training/TrainingDetails";
import TrainingResources from "../pages/training-resources";
import Teaser from "../pages/teaser/TeaserTemp";

function App() {
  useWebVitals();
  useAuthToken();

  return (
    <BrowserRouter>
      <ScrollRestoration />
      <TrackPageScroll />
      <AdminBar />
      <PageLayout>
        <AnimatePresence mode="wait">
          <Routes>
            <Route
              path={ROUTES.default.path}
              element={
                <AllowedComponent {...ROUTES.default} component={Home} />
              }
            />
            <Route
              path={ROUTES.benefits.path}
              element={
                <AllowedComponent
                  {...ROUTES.benefits}
                  component={PerkDetails}
                />
              }
            />
            {/* <Route
            path={ROUTES.coach.path}
            element={<AllowedComponent {...ROUTES.coach} component={Coach} />}
          /> */}
            <Route
              path={ROUTES.comingSoon.path}
              element={
                <AllowedComponent
                  {...ROUTES.comingSoon}
                  component={ComingSoon}
                />
              }
            />
            <Route
              path={ROUTES.dashboard.path}
              element={
                <AllowedComponent {...ROUTES.dashboard} component={Dashboard} />
              }
            />
            <Route
              path={ROUTES.dcsm.path}
              element={<AllowedComponent {...ROUTES.dcsm} component={Dcsm} />}
            />
            <Route
              path={ROUTES.expectations.path}
              element={
                <AllowedComponent
                  {...ROUTES.expectations}
                  component={Expectations}
                />
              }
            />
            <Route
              path={ROUTES.forgotPassword.path}
              element={
                <AllowedComponent
                  {...ROUTES.forgotPassword}
                  component={ForgotPassword}
                />
              }
            />
            <Route
              path={ROUTES.invite.path}
              element={
                <AllowedComponent {...ROUTES.invite} component={Invite} />
              }
            />
            <Route
              path={ROUTES.login.path}
              element={<AllowedComponent {...ROUTES.login} component={Login} />}
            />
            <Route
              path={ROUTES.notFound.path}
              element={
                <AllowedComponent {...ROUTES.notFound} component={NotFound} />
              }
            />
            <Route
              path={ROUTES.perkHighlights.path}
              element={
                <AllowedComponent
                  {...ROUTES.perkHighlights}
                  component={PerkHighlights}
                />
              }
            />
            <Route
              path={ROUTES.privacy.path}
              element={
                <AllowedComponent {...ROUTES.privacy} component={Privacy} />
              }
            />
            <Route
              path={ROUTES.profile.path}
              element={
                <AllowedComponent {...ROUTES.profile} component={Profile} />
              }
            />
            <Route
              path={ROUTES.recap.path}
              element={<AllowedComponent {...ROUTES.recap} component={Recap} />}
            />
            <Route
              path={ROUTES.register.path}
              element={
                <AllowedComponent {...ROUTES.register} component={Register} />
              }
            />
            <Route
              path={ROUTES.registerUser.path}
              element={
                <AllowedComponent
                  {...ROUTES.registerUser}
                  component={RegisterUser}
                />
              }
            />
            <Route
              path={ROUTES.requestAgencyId.path}
              element={
                <AllowedComponent
                  {...ROUTES.requestAgencyId}
                  component={RequestAgencyId}
                />
              }
            />
            <Route
              path={ROUTES.resetPassword.path}
              element={
                <AllowedComponent
                  {...ROUTES.resetPassword}
                  component={ResetPassword}
                />
              }
            />
            <Route
              path={ROUTES.shopping.path}
              element={
                <AllowedComponent {...ROUTES.shopping} component={Shopping} />
              }
            >
              <Route
                path={ROUTES.shoppingBonusChoose.path}
                element={
                  <AllowedComponent
                    {...ROUTES.shoppingBonusChoose}
                    component={ShoppingBonusChoose}
                  />
                }
              />
              <Route
                path={ROUTES.shoppingBonusConfirmationLater.path}
                element={
                  <AllowedComponent
                    {...ROUTES.shoppingBonusConfirmationLater}
                    component={ShoppingBonusConfirmationLater}
                  />
                }
              />
              <Route
                path={ROUTES.shoppingBonusConfirmationNow.path}
                element={
                  <AllowedComponent
                    {...ROUTES.shoppingBonusConfirmationNow}
                    component={ShoppingBonusConfirmationNow}
                  />
                }
              />
              <Route
                path={ROUTES.shoppingBonusIntro.path}
                element={
                  <AllowedComponent
                    {...ROUTES.shoppingBonusIntro}
                    component={ShoppingBonusIntro}
                  />
                }
              />
              <Route
                path={ROUTES.shoppingBonusReviewNow.path}
                element={
                  <AllowedComponent
                    {...ROUTES.shoppingBonusReviewNow}
                    component={ShoppingBonusReviewNow}
                  />
                }
              />
              <Route
                path={ROUTES.shoppingChoose.path}
                element={
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8 }}
                  >
                    <AllowedComponent
                      {...ROUTES.shoppingChoose}
                      component={ShoppingChoose}
                    />
                  </motion.div>
                }
              />
              <Route
                path={ROUTES.shoppingConfirmation.path}
                element={
                  <AllowedComponent
                    {...ROUTES.shoppingConfirmation}
                    component={ShoppingConfirmation}
                  />
                }
              />
              <Route
                path={ROUTES.shoppingIntro.path}
                element={
                  <AllowedComponent
                    {...ROUTES.shoppingIntro}
                    component={ShoppingIntro}
                  />
                }
              />
              <Route
                path={ROUTES.shoppingReview.path}
                element={
                  <AllowedComponent
                    {...ROUTES.shoppingReview}
                    component={ShoppingReview}
                  />
                }
              />
            </Route>
            <Route
              path={ROUTES.status.path}
              element={
                <AllowedComponent {...ROUTES.status} component={Status} />
              }
            />
            <Route
              path={ROUTES.teaser.path}
              element={
                <AllowedComponent {...ROUTES.teaser} component={Teaser} />
              }
            />
            <Route
              path={ROUTES.termsConditions.path}
              element={
                <AllowedComponent
                  {...ROUTES.termsConditions}
                  component={TermsConditions}
                />
              }
            />
            <Route
              path={ROUTES.termsUse.path}
              element={
                <AllowedComponent {...ROUTES.termsUse} component={TermsUse} />
              }
            />
            <Route
              path={ROUTES.training.path}
              element={
                <AllowedComponent {...ROUTES.training} component={Training} />
              }
            />
            <Route
              path={ROUTES.thankYou.path}
              element={
                <AllowedComponent {...ROUTES.thankYou} component={ThankYou} />
              }
            />
            <Route
              path={ROUTES.trainingDetails.path}
              element={
                <AllowedComponent
                  {...ROUTES.trainingDetails}
                  component={TrainingDetails}
                />
              }
            />
            <Route
              path={ROUTES.trainingResources.path}
              element={
                <AllowedComponent
                  {...ROUTES.trainingResources}
                  component={TrainingResources}
                />
              }
            />
          </Routes>
        </AnimatePresence>
      </PageLayout>
    </BrowserRouter>
  );
}

const MemoizedApp = memo(App);

function AppWithData() {
  useLoadData();
  return <MemoizedApp />;
}

export default AppWithData;
