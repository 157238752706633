import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAnimate } from "framer-motion";

import formatNumber, {
  convertDecimalToPercent,
} from "../../utilities/format-number";
import ROUTES from "../../data/constants/routes";
import { updateShoppingMethod } from "../../features/shopping/ShoppingRedux";
import trackEvent from "../../features/tracking/track-event";
import useUserAgency from "../../features/user/use-user-agency";

import AgencyLogo from "../../components/agency-logo/AgencyLogo";
import MastheadAlt from "../../components/masthead-alt/MastheadAlt";
import VimeoPlayer from "../../components/vimeo-player/VimeoPlayer";

import { Button, Typography } from "@uhc-tempo/components";

const { H1 } = Typography;

export default function ShoppingIntro(props) {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const videoUrl =
    "https://player.vimeo.com/video/913119229?h=87306bc2cb&badge=0&app_id=58479&dnt=1";
  // "https://player.vimeo.com/video/913119229?h=3457465d6f&dnt=1";

  const { first_name } = useSelector((state) => state.user) || {};
  const { logo, name } = useUserAgency();
  const { scorecardData } = useUserAgency();

  const [startPlayer, setStartPlayer] = useState(false);
  const [scope, animate] = useAnimate();

  const textRef1 = useRef();
  const textRef2 = useRef();
  const textRef3 = useRef();

  function handleStart() {
    dispatch(updateShoppingMethod("choose"))
      .unwrap()
      .then(async (response) => {
        await animate(
          "#animate-cta",
          { opacity: 0 },
          { duration: 0.8, ease: "easeInOut" }
        );
        trackEvent({ action: "shopping_method_choose" });
        navigate(ROUTES.shoppingChoose.path);
      });
  }

  const animateCTA = async () => {
    await animate("#animate-video", { display: "none" }, { duration: 0 });
    await animate("#animate-cta", { display: "block" }, { duration: 0 });
    await animate(
      "#animate-cta",
      { opacity: 1 },
      { duration: 0.8, delay: 0.5, ease: "easeInOut" }
    );
  };

  useEffect(() => {
    const onEnterAnimation = async () => {
      await animate(
        scope.current,
        { height: "100%", width: "100%" },
        { duration: 0.8, ease: "easeInOut" }
      );
      await animate(
        "#logo, #text1",
        { display: "block", opacity: 1 },
        { duration: 0.8, ease: "easeInOut" }
      );

      await animate(
        "#text1",
        { opacity: 0 },
        { duration: 0.8, delay: 3, ease: "easeInOut" }
      );
      await animate("#text1", { display: "none" }, { duration: 0 });
      await animate(
        "#text2",
        { display: "block", opacity: 1 },
        { duration: 0.8, ease: "easeInOut" }
      );
      await animate(
        "#underline1",
        { width: "100%", opacity: 1 },
        { duration: 0.8, ease: "easeInOut" }
      );

      await animate(
        "#text2, #underline1",
        { opacity: 0 },
        { duration: 0.8, delay: 2.25, ease: "easeInOut" }
      );
      await animate("#text2", { display: "none" }, { duration: 0 });
      await animate(
        "#text3",
        { display: "block", opacity: 1 },
        { duration: 0.8, ease: "easeInOut" }
      );
      await animate(
        "#underline2",
        { width: "100%", opacity: 1 },
        { duration: 0.8, ease: "easeInOut" }
      );

      await animate(
        "#text3, #underline2",
        { opacity: 0 },
        { duration: 0.8, delay: 2.25, ease: "easeInOut" }
      );
      await animate("#text3", { display: "none" }, { duration: 0 });
      await animate(
        "#text4",
        { display: "block", opacity: 1 },
        { duration: 0.8, ease: "easeInOut" }
      );
      await animate(
        "#underline3",
        { width: "100%", opacity: 1 },
        { duration: 0.8, ease: "easeInOut" }
      );

      await animate(
        "#logo, #text4, #underline3",
        { opacity: 0 },
        { duration: 0.8, delay: 2.25, ease: "easeInOut" }
      );
      await animate("#animation-group", { display: "none" }, { duration: 0 });
      await animate(scope.current, { maxWidth: "100vw" }, { duration: 0 });
      await animate(
        scope.current,
        {
          height: "100vh",
          width: "100vw",
          borderRadius: 0,
          margin: 0,
          padding: 0,
        },
        { duration: 0.8, ease: "easeInOut" }
      );
      setStartPlayer(true);
      await animate(
        "#animate-video",
        { display: "flex", opacity: 1 },
        { duration: 0.8, ease: "easeInOut" }
      );
    };

    onEnterAnimation();
  }, [animate, scope]);

  return (
    <div className="ua-bg-primary-one">
      <div className="ua-shopping-intro--header">
        <MastheadAlt component="header" />
      </div>
      <section className="ua-display-flex ua-flex-column ua-align-items-center ua-justify-content-center ua-shopping-intro">
        <div className="card-wrapper">
          <div
            ref={scope}
            className="ua-bg-primary-three ua-border-radius-sm ua-text-center-md intro-card"
          >
            <div
              id="animation-group"
              className="ua-flex-column ua-justify-content-flex-start ua-align-items-center ua-full-width ua-shopping-intro--inner"
            >
              <AgencyLogo
                id="logo"
                name={name}
                src={logo}
                width={350}
                className="ua-margin-auto"
              />

              <H1 className="ua-margin-none ua-flex-grow " id="text1">
                Thank you for your business with UHC.
                <div className="ua-font-sans ua-font-weight-normal ua-line-height-1_5 ua-intro__subheading">
                  Your agency achieved these <strong>3</strong> UA criteria
                  requirements.
                </div>
                <div
                  id="underline0"
                  className="ua-bg-primary-three ua-animated-underline"
                />
              </H1>
              <H1
                className="ua-margin-none ua-line-height-1_3 ua-flex-grow "
                id="text2"
              >
                Your agency entrusted
                <br /> UHC with{" "}
                <span
                  id="textRef1"
                  className="ua-display-inline-block ua-text-accent-two"
                  ref={textRef1}
                >
                  {formatNumber(scorecardData.MedicalMembers, 0, true)}
                  <div
                    id="underline1"
                    className="ua-bg-accent-two ua-animated-underline"
                  />
                </span>{" "}
                members.
              </H1>
              <H1 className="ua-margin-none ua-flex-grow " id="text3">
                Your agency had a{" "}
                <span
                  className="ua-display-inline-block ua-text-accent-two"
                  ref={textRef2}
                >
                  {convertDecimalToPercent(
                    scorecardData.MedicalNetChangePercent
                  )}
                  %
                  <div
                    id="underline2"
                    className="ua-bg-accent-two ua-animated-underline"
                  />
                </span>{" "}
                <br />
                medical net change.
              </H1>
              <H1
                className="ua-margin-none ua-line-height-1_3 ua-flex-grow "
                id="text4"
              >
                And a{" "}
                <span
                  className="ua-display-inline-block ua-text-accent-two"
                  ref={textRef3}
                >
                  {convertDecimalToPercent(
                    scorecardData.SpecialtyNetChangePercent
                  )}
                  %
                  <div
                    id="underline3"
                    className="ua-bg-accent-two ua-animated-underline"
                  />
                </span>{" "}
                specialty net change.
              </H1>
            </div>

            <div id="animate-video" className="video-container">
              <VimeoPlayer
                controls={false}
                onVideoEnd={animateCTA}
                vimeoUrl={videoUrl}
                autoplay={startPlayer}
                muted
              />
            </div>

            <div id="animate-cta" className="intro-cta">
              <H1 className="ua-display-block ua-intro-cta__display">
                Congratulations from UnitedHealthcare
                <br /> on achieving 2025 Platinum Status.
                <div className="ua-font-sans ua-font-weight-normal ua-line-height-1_5 ua-margin-lg-top ua-intro-cta__subheading">
                  {first_name}, it&apos;s time to pick your perks!
                </div>
              </H1>
              <Button
                className="ua-bg-supporting-four ua-border"
                onClick={handleStart}
                size="lg"
              >
                Shop now
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
